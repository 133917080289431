import { type FC } from 'react'

import { withColor } from 'lib'
import { ck33Fields } from 'resources/vmrs'
import { Stack, Typography } from 'ui'

import TopContent from './TopContent'
import { useDrilldownContext } from './context'

const ComponentHeader: FC = () => {
    const { modifiers, data, dateHelpers } = useDrilldownContext()
    const firstJob = data?.[0]

    if (!firstJob) {
        return null
    }

    const component = firstJob.componentData

    return (
        <TopContent>
            <Stack spacing="4px">
                {modifiers.filter.dateCompleted ? (
                    <Typography
                        variant="subtitle1"
                        color={withColor('text.primary')}
                    >
                        {dateHelpers.formatSource(modifiers.filter.dateCompleted, true)}
                    </Typography>
                ) : null}
                <Typography
                    variant="subtitle1"
                    color={withColor('text.primary')}
                >
                    {ck33Fields.self.formatCode(component.code, component.level)}
                </Typography>
                <Typography
                    variant="body2"
                    color={withColor('text.primary')}
                >
                    {component.text}
                </Typography>
            </Stack>
        </TopContent>
    )
}

export default ComponentHeader
