import { type FC } from 'react'

import { type Identifier } from 'appTypes'

import DrawerJobCard from './DrawerJobCard'
import {
    ComponentHeader,
    groupByDate,
    Section,
    useDrilldownContext,
    useDrilldownDrawer,
} from './Drilldown'

export const useComponentDrilldown = () => {
    const open = useDrilldownDrawer({
        title: 'Component Drill Down',
        renderBelowHeader: () => <ComponentHeader />,
        renderContent: () => <Content />,
    })

    return (component: Identifier) => {
        open({ filter: { component } })
    }
}

const Content: FC = () => {
    const { data, dateHelpers } = useDrilldownContext()

    const dates = groupByDate(data, dateHelpers)

    return (
        <>
            {dates.map((date) => (
                <Section key={date.group}>
                    <Section.Header>
                        <Section.Title>{date.group}</Section.Title>
                    </Section.Header>
                    <Section.Cards>
                        {date.values.map((job) => (
                            <DrawerJobCard
                                job={job}
                                key={job.id}
                            />
                        ))}
                    </Section.Cards>
                </Section>
            ))}
        </>
    )
}
