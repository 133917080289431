import { RadioButtonGroupInput } from 'react-admin'
import { useFormContext, useFormState } from 'react-hook-form'

import { CheckboxInput, costMaskParams, percentageMaskParams, TextInput } from 'components'
import {
    type FormatedFormError,
    maxLengthValidation,
    requiredValidation,
    validateMaxLength,
} from 'core'
import { Alert, Box, Typography } from 'ui'

import {
    calculateOnLaborLabel,
    calculateOnLaborSource,
} from '../fields/calculateOnLabor/calculateOnLabor'
import {
    calculateOnPartsLabel,
    calculateOnPartsSource,
} from '../fields/calculateOnParts/calculateOnParts'
import {
    calculateOnServicesLabel,
    calculateOnServicesSource,
} from '../fields/calculateOnServices/calculateOnServices'
import { flatFee } from '../fields/flatFee'
import { maxAmount } from '../fields/maxAmoint'
import { minAmount } from '../fields/minAmount'
import { nameLabel, nameSource } from '../fields/name/name'
import { percentage } from '../fields/percentage'
import { taxableLabel, taxableSource } from '../fields/taxable/taxable'

const nameValidation = [requiredValidation, maxLengthValidation]
const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]
const amountValidator = [validateMaxLength(10, 'Invalid value')]
const typeChoices: { id: string; name: string }[] = [
    { id: 'PERCENTAGE', name: 'Percentage' },
    { id: 'FLAT', name: 'Flat Fee' },
]

const PTFeeForm = () => {
    return (
        <>
            <TextInput
                source={nameSource}
                label={nameLabel}
                validate={nameValidation}
            />
            <CheckboxInput
                source={taxableSource}
                label={taxableLabel}
                helperText={false}
            />
            <Alert
                severity="info"
                sx={{ mt: '7px' }}
            >
                This fee will be automatically applied to an invoice once specified conditions are
                fulfilled.
            </Alert>
            <TypeRadioButtonGroup />

            <TypeChangeHandler />
        </>
    )
}

const TypeRadioButtonGroup = () => {
    const { watch, getValues, setValue } = useFormContext()
    const percentageValue = watch(percentage.source) || getValues(percentage.source)

    return (
        <RadioButtonGroupInput
            defaultValue={percentageValue ? 'PERCENTAGE' : 'FLAT'}
            label={false}
            source="type"
            onChange={() => {
                setValue(flatFee.source, null)
                setValue(percentage.source, null)
            }}
            choices={typeChoices}
        />
    )
}
const TypeChangeHandler = () => {
    const { watch, getValues } = useFormContext()
    const type = watch('type') || getValues('type')

    if (type === 'FLAT') {
        return (
            <Box key="FLAT">
                <TextInput
                    source={flatFee.source}
                    label={flatFee.label}
                    validate={qtyAndCostValidator}
                    {...costMaskParams}
                />
            </Box>
        )
    }

    return (
        <Box key="PERCENTAGE">
            <TextInput
                source={percentage.source}
                label={percentage.label}
                validate={qtyAndCostValidator}
                {...percentageMaskParams}
            />

            <CheckboxInput
                source={calculateOnPartsSource}
                label={calculateOnPartsLabel}
                helperText={false}
            />
            <CheckboxInput
                source={calculateOnLaborSource}
                label={calculateOnLaborLabel}
                helperText={false}
            />
            <CheckboxInput
                source={calculateOnServicesSource}
                label={calculateOnServicesLabel}
                helperText={false}
            />
            <ErrorHandler />
            <TextInput
                sx={{ mt: '16px' }}
                source={minAmount.source}
                label={minAmount.label}
                validate={amountValidator}
                {...costMaskParams}
            />

            <TextInput
                source={maxAmount.source}
                label={maxAmount.label}
                validate={amountValidator}
                {...costMaskParams}
            />
        </Box>
    )
}

const ErrorHandler = () => {
    const error = useFormState().errors.calculateOn as FormatedFormError

    if (error) {
        return (
            <Typography
                variant="helperText"
                color={(theme) => theme.palette.error.main}
            >
                {error.message.message}
            </Typography>
        )
    }
    return null
}
export default PTFeeForm
