import { type FC } from 'react'

import { Order } from 'appTypes'
import TestErrorBoundary from 'components/TestErrorBoundary'
import { ck33Fields } from 'resources/vmrs'

import { type DateResponse, useCostReportContext } from '../CostReportContext'

import DrawerJobCard from './DrawerJobCard'
import {
    PeriodChanger,
    Section,
    useDrilldownDrawer,
    useDrilldownContext,
    groupByComponent,
} from './Drilldown'

export const usePeriodDrilldown = () => {
    const { data, dateHelpers } = useCostReportContext()
    const open = useDrilldownDrawer({
        renderBelowHeader: () => (
            <TestErrorBoundary>
                <PeriodChanger dates={data?.dates} />
            </TestErrorBoundary>
        ),
        renderContent: () => <Content />,
    })

    return (date: DateResponse) => {
        open(
            { filter: { dateCompleted: date }, sort: { field: 'component', order: Order.ASC } },
            {
                title:
                    dateHelpers.type === 'month'
                        ? 'Monthly Costs Drill Down'
                        : 'Annual Costs Drill Down',
            },
        )
    }
}

const Content: FC = () => {
    const { data } = useDrilldownContext()

    const components = groupByComponent(data)

    return (
        <>
            {components.map((component) => (
                <Section key={component.group.id}>
                    <Section.Header>
                        <Section.Title>
                            {ck33Fields.self.formatCode(
                                component.group.code,
                                component.group.level,
                            )}
                        </Section.Title>
                        <Section.Text>{component.group.text}</Section.Text>
                    </Section.Header>
                    <Section.Cards>
                        {component.values.map((job) => (
                            <DrawerJobCard
                                job={job}
                                key={job.id}
                            />
                        ))}
                    </Section.Cards>
                </Section>
            ))}
        </>
    )
}
