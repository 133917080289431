import { type FC } from 'react'

import Icons from 'assets/icons'
import {
    CollapsibleContent,
    CollapsibleInfoCard,
    CollapsibleToggleButton,
    InfoCardHeader,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
} from 'components'
import { cssVariables, globalClassNames } from 'lib'
import { ItemRow, ItemsTotal } from 'resources/jobs'
import { woFields } from 'resources/workOrders'
import { Divider, IconElement, TextWithIcon, Typography } from 'ui'

import { type ResponseType } from './Drilldown'

const DrawerJobCard: FC<{ job: ResponseType }> = ({ job }) => {
    return (
        <CollapsibleInfoCard
            disableActiveState
            sx={{
                boxShadow: 'none',
                backgroundColor: (theme) => theme.palette.other.grey,
                [cssVariables.iconSize]: '16px',
            }}
        >
            <InfoCardHeader
                noDivider
                title={
                    <div className={globalClassNames.ellipsis}>
                        <InfoCardTitle>{job.name}</InfoCardTitle>
                        <Typography variant="helperText">
                            {woFields.self.linkValue(job.workOrderData)}
                        </Typography>
                        <br />
                        {job.reasonForRepairData ? (
                            <Typography variant="helperText">
                                {job.reasonForRepairData.description}
                            </Typography>
                        ) : null}
                    </div>
                }
            />
            <InfoCardRows>
                <InfoCardRowBase
                    label={
                        <TextWithIcon icon={<IconElement component={Icons.ListAltOutlined} />}>
                            <Typography variant="chartTitle">
                                {job.itemsCount} Line Items
                            </Typography>
                        </TextWithIcon>
                    }
                >
                    <Typography variant="caption">
                        <CollapsibleToggleButton />
                    </Typography>
                </InfoCardRowBase>
            </InfoCardRows>
            <CollapsibleContent>
                <Divider />
                <InfoCardRows>
                    {job.items?.map((item) => (
                        <ItemRow
                            key={item.id}
                            link={false}
                            item={item}
                        />
                    ))}
                </InfoCardRows>
            </CollapsibleContent>
            <ItemsTotal
                total={job.cost}
                sx={{ mb: 0 }}
            />
        </CollapsibleInfoCard>
    )
}

export default DrawerJobCard
