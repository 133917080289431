import { useEffect, type FC } from 'react'

import { useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { SelectInput, TextInput, costMaskParams, percentageMaskParams } from 'components'
import { ReferenceInput, requiredValidation, useDataProviderConfig } from 'core'
import { customerFields, CustomerInput } from 'resources/customers'
import {
    getDefaultText,
    getPricingTierResource,
    PricingTierTypes,
    type PtItemModel,
    ptLaborFields,
    type PtLaborModel,
    ptPartFields,
    ptServiceFields,
    type PtServicesModel,
    type PtTaxesModel,
    ptTaxFields,
} from 'resources/pricingTiers'
import { shopFields, ShopInput } from 'resources/shops'
import { SectionTitleSmall } from 'ui'

import { getDefaultOptionId, getOptionId, isIdDefaultOption } from './utils'

export const CustomerPricingForm = () => {
    const { setValue } = useFormContext()

    return (
        <>
            <CustomerInput
                label={customerFields.self.label}
                source={customerFields.self.source}
                disabled
                required
            />
            <ShopInput
                contextType="customer"
                disabled
                validate={requiredValidation}
            />

            <SectionTitleSmall>{ptPartFields.self.label}</SectionTitleSmall>
            <PricingTierInput
                label="Customer Price Tier"
                source={ptPartFields.self.source}
                type={PricingTierTypes.PARTS}
            />

            <SectionTitleSmall>{ptLaborFields.self.label}</SectionTitleSmall>
            <PricingTierInput
                label="Labor Rate"
                source={ptLaborFields.self.source}
                type={PricingTierTypes.LABOR}
                onSelectedChange={(value: PtLaborModel) => {
                    setValue(ptLaborFields.rate.source, value.price || 0)
                }}
            />
            <TextInput
                source={ptLaborFields.rate.source}
                label={ptLaborFields.rate.label}
                disabled
                {...costMaskParams}
            />

            <SectionTitleSmall>{ptServiceFields.self.label}</SectionTitleSmall>
            <PricingTierInput
                label="Customer Price Tier"
                source={ptServiceFields.self.source}
                type={PricingTierTypes.SERVICES}
                onSelectedChange={(value: PtServicesModel) => {
                    setValue(ptServiceFields.markup.source, value.markupPercent || 0)
                }}
            />
            <TextInput
                source={ptServiceFields.markup.source}
                label={ptServiceFields.markup.label}
                disabled
                {...percentageMaskParams}
            />

            <SectionTitleSmall>{ptTaxFields.self.label}</SectionTitleSmall>
            <PricingTierInput
                label="Tax"
                source={ptTaxFields.self.source}
                type={PricingTierTypes.TAXES}
                onSelectedChange={(value: PtTaxesModel) => {
                    setValue('percentageOnTotal', value.markupPercent || 0)
                }}
            />
            <TextInput
                source="percentageOnTotal"
                label={ptTaxFields.percentageOnTotal.label}
                {...percentageMaskParams}
                disabled
            />
        </>
    )
}

interface PricingTierInputProps {
    source: string
    label: string
    type: PricingTierTypes
    onSelectedChange?: (value: PtItemModel) => void
}

const PricingTierInput = ({ source, label, type, onSelectedChange }: PricingTierInputProps) => {
    const { watch, getValues } = useFormContext()

    const shop = watch(shopFields.self.source) || getValues(shopFields.self.source)
    const reference = getPricingTierResource(shop, type)

    useDataProviderConfig(reference, {
        getList: {
            prepareDataAfterResponse: (data: PtItemModel[]) => {
                const defaultOption = data.find((item) => item.isDefault)
                return [{ ...defaultOption, id: getDefaultOptionId(defaultOption.id) }, ...data]
            },
        },
        getMany: {
            getId: (id) => getOptionId(id),
        },
    })

    return (
        <ReferenceInput
            disabled={!shop}
            reference={reference}
            source={source}
            perPage={100}
            // something different to be included as the cache key
            // in order to not interfere with the original response cache
            queryOptions={{
                meta: {
                    cacheKey: type,
                },
            }}
        >
            <>
                {onSelectedChange && <RiOnSelectedChange onChange={onSelectedChange} />}
                <SelectInput
                    disableEmptyValue
                    optionText={optionText}
                    label={label}
                    validate={requiredValidation}
                />
            </>
        </ReferenceInput>
    )
}

const optionText = (option: PtItemModel) => {
    if (isIdDefaultOption(option.id)) {
        return getDefaultText(option.name)
    }

    return option.name
}

const RiOnSelectedChange: FC<{ onChange: (value: PtItemModel) => void }> = ({ onChange }) => {
    const { selectedChoices } = useChoicesContext<PtItemModel>()

    const choice = selectedChoices[0]

    useEffect(() => {
        if (choice) {
            onChange(choice)
        }
    }, [choice?.id])

    return null
}
