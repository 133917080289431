import { type ReactNode, useState, type FC } from 'react'

import { DistanceLabel, formatMoney } from 'components'
import { type DisplayedFilters } from 'core'
import { reportsUrls } from 'resources/reports'
import { meterTypesConfig } from 'resources/units'
import {
    BoxContainer,
    IconElement,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Tooltip,
    LinkButton,
} from 'ui'

import { useCostReportContext, type ValueData } from '../../CostReportContext'

import LineChart from './LineChart'
import TitleTooltip from './TitleTooltip'
import WidgetSkeleton from './WidgetSkeleton'
import WidgetsContainer from './WidgetsContainer'
import WidgetsNoData from './WidgetsNoData'
import { formatData } from './utils'

type Types = 'ODOMETER' | 'ENGINE_HOURS'

const UtilizationWidget = () => {
    const { data, isLoading } = useCostReportContext()
    const [type, setType] = useState<Types>('ODOMETER')
    const widgetConfig = config[type]

    if (isLoading) {
        return <WidgetSkeleton title="Unit Utilization" />
    }

    const widgetData = data[widgetConfig.source]
    if (!widgetData?.total) {
        return (
            <WidgetsNoData
                title={
                    <Title
                        type={type}
                        setType={setType}
                    />
                }
                text={widgetText}
            />
        )
    }

    const cost = widgetData[widgetConfig.costSource]

    const { leftAxisValues, formattedData } = formatData(widgetData.data)
    return (
        <WidgetsContainer>
            <Typography
                variant="chartTitle"
                color="text.secondary"
            >
                <Title
                    type={type}
                    setType={setType}
                />
            </Typography>
            <Typography
                variant="h5"
                color="text.primary"
            >
                {widgetConfig.format(widgetData.total)} <TitleTooltip tooltipText={widgetText} />
            </Typography>
            {typeof cost === 'number' ? (
                <Typography variant="inputLabel">
                    {formatMoney(cost)} {widgetConfig.abbr}{' '}
                    <LinkButton to={createLink(data.linkParams)}>View Report</LinkButton>
                </Typography>
            ) : null}
            <LineChart
                data={formattedData}
                leftAxisValues={leftAxisValues}
                formatValue={widgetConfig.format}
                bottomAxisValues={widgetData.xAxis}
            />
        </WidgetsContainer>
    )
}

export default UtilizationWidget

const createLink = (range: object) => {
    return (
        reportsUrls.costPerMeter +
        `?displayedFilters=${encodeURIComponent(
            JSON.stringify({
                info: {
                    period: 'custom',
                },
            } as DisplayedFilters),
        )}&filter=
        ${encodeURIComponent(JSON.stringify(range))}`
    )
}
const config: {
    [key in Types]: {
        format: (v: number, formattedValue?: string) => ReactNode
        source: keyof Pick<ValueData, 'unitUtilizationDistance' | 'unitUtilizationHours'>
        abbr: ReactNode
        costSource:
            | keyof Pick<ValueData['unitUtilizationDistance'], 'costPerDistance'>
            | keyof Pick<ValueData['unitUtilizationHours'], 'costPerHour'>
    }
} = {
    ODOMETER: {
        format: (value, formattedValue) => {
            return (
                <>
                    {formattedValue || value} {meterTypesConfig.ODOMETER.adornment}
                </>
            )
        },
        source: 'unitUtilizationDistance',
        abbr: (
            <>
                CP
                <DistanceLabel
                    variant="short-abbr"
                    textCase="upper"
                />
            </>
        ),
        costSource: 'costPerDistance',
    },
    ENGINE_HOURS: {
        format: (value, formattedValue) => {
            return (
                <>
                    {formattedValue || value} {meterTypesConfig.ENGINE_HOURS.adornment}
                </>
            )
        },
        source: 'unitUtilizationHours',
        abbr: 'CPH',
        costSource: 'costPerHour',
    },
}

interface TitleProps {
    type: Types
    setType: React.Dispatch<React.SetStateAction<Types>>
}
const Title: FC<TitleProps> = ({ type, setType }) => {
    return (
        <BoxContainer
            justifyContent="space-between"
            height="18px"
        >
            Unit Utilization
            <ToggleButtonGroup
                value={type}
                exclusive
                onChange={(_, value) => {
                    if (value) {
                        setType(value)
                    }
                }}
            >
                <ToggleButton
                    value="ODOMETER"
                    sx={{ width: '24px', height: '24px' }}
                    aria-label="Odometer"
                >
                    <Tooltip title="Odometer">
                        <IconElement
                            size="16px"
                            component={meterTypesConfig.ODOMETER.Icon}
                        />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton
                    value="ENGINE_HOURS"
                    sx={{ width: '24px', height: '24px' }}
                    aria-label="Engine Hours"
                >
                    <Tooltip title="Engine Hours">
                        <IconElement
                            size="16px"
                            component={meterTypesConfig.ENGINE_HOURS.Icon}
                        />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </BoxContainer>
    )
}

const widgetText =
    'Displays the total mileage or engine hours accumulated by this unit during the selected period.'
