import { type ReactNode, type FC } from 'react'

import { type Datum, type Point, ResponsiveLine } from '@nivo/line'
import { TooltipWrapper } from '@nivo/tooltip'

import { useTheme } from 'lib'
import { TooltipBox } from 'pages/Dashboard/components'
import { LineChartPoint } from 'pages/Dashboard/components/Widgets/components'
import { BoxContainer, Stack, Typography } from 'ui'

import { baseValueFormat } from './utils'

interface LineChartProps {
    data: Datum[]
    leftAxisValues: (number | string)[]
    bottomAxisValues: (number | string)[]
    formatValue: (value: number | string, formattedValue?: string) => ReactNode
}

const LineChart: FC<LineChartProps> = ({ data, leftAxisValues, formatValue, bottomAxisValues }) => {
    const theme = useTheme()
    const isSingleValue = leftAxisValues.length === 1
    return (
        <BoxContainer
            height="100px"
            mt="auto"
        >
            <ResponsiveLine
                theme={{
                    axis: {
                        ticks: {
                            text: theme.typography.chartLabel,
                        },
                    },
                }}
                margin={{ top: 10, right: 24, bottom: 24, left: 47 }}
                curve="linear"
                enableCrosshair={false}
                data={[
                    {
                        id: 'chart',
                        data,
                    },
                ]}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    tickValues: bottomAxisValues,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickRotation: 0,
                    tickValues: leftAxisValues,
                    format: (value) => formatValue(value, baseValueFormat(value)),
                    renderTick: ({ x, y, value, format }) => (
                        <Typography
                            variant="chartLabel"
                            component="text"
                            x={-47}
                            textAnchor="start"
                            dominantBaseline="middle"
                            transform={`translate(${x},${y})`}
                            style={{ transition: 'opacity 0.5s ease-in-out', opacity: 1 }}
                        >
                            {format(value)}
                        </Typography>
                    ),
                }}
                animate={false}
                enableGridX={false}
                enableGridY={false}
                pointSymbol={() => (
                    <LineChartPoint
                        r={4}
                        strokeWidth={2}
                    />
                )}
                useMesh
                tooltip={({ point }) => {
                    return (
                        <ChartTooltip
                            {...point}
                            formatValue={formatValue}
                        />
                    )
                }}
                yScale={{
                    type: 'linear',
                    min: 0,
                    max: isSingleValue ? 1 : 'auto',
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                layers={[
                    'grid',
                    'markers',
                    'axes',
                    'areas',
                    'crosshair',
                    'lines',
                    'slices',
                    'points',
                    'mesh',
                    'legends',
                    ({ innerHeight }) => (
                        <line
                            x1={0}
                            x2="100%"
                            y1={innerHeight}
                            y2={innerHeight}
                            stroke={theme.palette.other.divider}
                            strokeWidth={1}
                        />
                    ),
                ]}
                colors={theme.palette.primary.main}
            />
        </BoxContainer>
    )
}

export default LineChart

const ChartTooltip: FC<Point & Pick<LineChartProps, 'formatValue'>> = ({ data, formatValue }) => {
    return (
        <TooltipWrapper
            anchor="top"
            position={[0, 0]}
        >
            <TooltipBox>
                <Stack>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {formatValue(data.yFormatted)}
                    </Typography>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {data.xFormatted}
                    </Typography>
                </Stack>
            </TooltipBox>
        </TooltipWrapper>
    )
}
