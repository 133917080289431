import { type Point } from '../../CostReportContext'

export const baseValueFormat = (value: number) => {
    if (value === 0) {
        return String(value)
    }

    if (value >= 1000) {
        const number = value / 1000
        if (number >= 100) {
            return `${number.toFixed(0)}k`
        }
        return `${number.toFixed(1)}k`
    }
    if (value < 10) {
        return String(value.toFixed(2))
    }
    return String(Math.round(value))
}

export const formatData = (data: Point[]) => {
    const maxValue = Math.max(...data.map((item) => item.value))
    const leftAxisValues =
        maxValue === 0 ? [maxValue] : [maxValue, (maxValue / 3) * 2, maxValue / 3, 0]
    const formattedData = data.map(({ value, date }) => ({ y: value, x: date }))

    return { leftAxisValues, formattedData }
}
