import { type FC } from 'react'

import { BooleanInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { DateInput, TextInput } from 'components'
import { maxLengthValidation, requiredValidation } from 'core'
import { invoiceFields } from 'resources/invoices'
import { paymentTermFields, PaymentTermInput } from 'resources/paymentTerms'
import { Divider, Stack } from 'ui'

import { type WorkOrderModel } from '../../types'

interface Props {
    workOrder: WorkOrderModel
}

const InvoicingContent: FC<Props> = ({ workOrder }) => {
    return (
        <Stack gap="13px">
            <Divider />
            <BooleanInput
                defaultValue={workOrder.shopData.invoicingOnWoClose}
                source="invoicingOnWoClose"
                label="Invoice on WO Close"
            />
            <InvoicingInputs workOrder={workOrder} />
        </Stack>
    )
}

export default InvoicingContent

const InvoicingInputs: FC<Props> = ({ workOrder }) => {
    const { getValues, watch } = useFormContext()
    const invoicingOnWoClose = watch('invoicingOnWoClose') || getValues('invoicingOnWoClose')

    if (!invoicingOnWoClose) {
        return null
    }

    return (
        <>
            <TextInput
                label={invoiceFields.poNumber.label}
                source={invoiceFields.poNumber.source}
                validate={maxLengthValidation}
                defaultValue={workOrder.salesOrderData.poNumber}
            />

            <DateInput
                source={invoiceFields.invoiceDate.source}
                label={invoiceFields.invoiceDate.label}
                validate={requiredValidation}
                defaultValue={new Date()}
            />

            <PaymentTermInput
                source={paymentTermFields.self.source}
                label={paymentTermFields.self.label}
                defaultValue={workOrder.unitData.customerData?.paymentTerm}
            />
        </>
    )
}
