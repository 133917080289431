import { type PeriodFilter } from 'components'
import {
    formatDate,
    getCurrentDay,
    getCurrentMonth,
    getLastDayOfMonth,
    getMonthName,
    getQuarterRange,
    isCurrenYear,
    parseDate,
} from 'lib'

import {
    type DateHelpers,
    type ComponentDate,
    type ResponseData,
    type ResponsePoint,
    type Point,
} from './types'

const maxDisplayedYears = 4

export const getYears = (yearsProp: ResponsePoint[]): number[] => {
    const years = yearsProp.map((item) => ({ ...item, date: item.year }))
    if (!years.length) {
        return []
    }
    if (years.length <= maxDisplayedYears) {
        return years.map((item) => item.year)
    }

    const first = years[0].year
    const last = years[years.length - 1].year

    const diff = Math.abs(last - first)

    for (let numberOfYears = maxDisplayedYears - 1; numberOfYears > 1; numberOfYears -= 1) {
        if (diff % numberOfYears !== 0) {
            continue
        }

        const step = diff / numberOfYears

        const result: number[] = []

        for (let j = 0; j <= numberOfYears; j += 1) {
            result.push(years[j * step].year)
        }

        return result
    }

    return [years[0].year, years[years.length - 1].year]
}

const getDateSource = (date: number) => {
    return String(date)
}

export const getTotalsIndexedByComponent = ({ totals }: ResponseData) => {
    return totals.reduce(
        (acc, item) => {
            if (!acc[item.component]) {
                acc[item.component] = {}
            }
            acc[item.component][getDateSource(item.date)] = item.value
            return acc
        },
        {} as { [key: string]: { [source: string]: number } },
    )
}

export const getComponentTotalsIndexedByComponent = ({ totalsByComponent }: ResponseData) => {
    return totalsByComponent.reduce(
        (acc, item) => {
            acc[item.component] = item.value
            return acc
        },
        {} as { [key: string]: number },
    )
}

export const getDateTotalsIndexedByDate = ({ totalsByTime }: ResponseData) => {
    return totalsByTime.reduce(
        (acc, item) => {
            acc[getDateSource(item.date)] = item.value
            return acc
        },
        {} as { [key: string]: number },
    )
}

export const getData = <ObjectType extends { data: ResponsePoint[]; total: number }>(
    obj: ObjectType,
    isAll: boolean,
): Omit<ObjectType, 'data'> & { xAxis: (number | string)[]; data: Point[] } => {
    if (isAll) {
        return {
            ...obj,
            data: obj.data.map((item) => ({ date: item.year, value: item.value })),
            xAxis: getYears(obj.data),
        }
    }

    const data = obj.data.map((item, index) => ({
        ...item,
        date: getQuarterRange(index + 1),
    }))

    return {
        ...obj,
        data,
        xAxis: data.map((item) => item.date),
    }
}

export const getDates = ({ years }: ResponseData, year: string | undefined): ComponentDate[] => {
    if (!year) {
        return years.map((item) => ({ source: getDateSource(item), date: item }))
    }

    return getMonths(year)
}

export const getMonths = (year: number | string, monthsCount = 12) => {
    return new Array(monthsCount).fill(0).map((_, index) => {
        return {
            source: `${year}-${getDateSource(index + 1)}`,
            date: `${getMonthName(index + 1)} ${year}`,
        }
    })
}

export const yearHelpers: DateHelpers = {
    type: 'year',
    format: (date) => String(new Date(date).getFullYear()),
    formatSource: (source) => String(source),
    toSource: (date) => date,
}

const monthFormat = (date: string | Date, longVariant: boolean) => {
    return formatDate(String(date), (format) =>
        longVariant ? format.fullMonthYear : format.shortMonthYear,
    )
}

export const monthHelpers: DateHelpers = {
    type: 'month',
    format: monthFormat,
    formatSource: (date, longVariant) => {
        const parsedDate = parseDate(date as string, 'yyyy-MM', new Date())
        return monthFormat(parsedDate, longVariant)
    },
    toSource: (date) => {
        return formatDate(date, 'yyyy-MM')
    },
}

export const getRange = ([startYear, endYear = startYear]: [number, number?]): PeriodFilter => {
    if (!startYear) {
        return {} as any
    }
    const startDate = new Date(startYear, 0, 1)
    const matchYear = isCurrenYear(endYear)
    const lastMonth = matchYear ? getCurrentMonth() : 11
    const lastDay = matchYear ? getCurrentDay() : getLastDayOfMonth(endYear, lastMonth)
    const endDate = new Date(endYear, lastMonth, lastDay, 23, 59, 59, 999)

    return {
        periodStart: formatBoundary(startDate),
        periodEnd: formatBoundary(endDate),
    }
}

const formatBoundary = (boundary: Date) => {
    return formatDate(boundary, (dateFormats) => dateFormats.serverDateTime)
}
