import { useRecordContext } from 'react-admin'

import { api } from 'core'
import { getCurrentMonth, isCurrenYear, useParams, useQuery } from 'lib'
import { createReportResource } from 'resources/reports'
import { type UnitModel } from 'resources/units'
import { CK33Levels } from 'resources/vmrs'

import { type CostReportContextValue, type ResponseData, type ValueData } from './types'
import {
    getComponentTotalsIndexedByComponent,
    getData,
    getDates,
    getDateTotalsIndexedByDate,
    getMonths,
    getRange,
    getTotalsIndexedByComponent,
    monthHelpers,
    yearHelpers,
} from './utils'

const useGetData = (): CostReportContextValue => {
    const { year } = useParams()
    const record = useRecordContext<UnitModel>()

    const unitId = record?.id
    const isAll = !year

    const filter = {
        unit: unitId,
        ...(year ? { year } : {}),
    }

    const result = useQuery(
        ['unit-cost', filter],
        async (): Promise<ValueData> => {
            const res: ResponseData = await api.get(endpoint, filter).catch(() => null)

            if (!res) {
                return {
                    years: [],
                    maintenanceCost: null,
                    unitUtilizationDistance: null,
                    unitUtilizationHours: null,
                    totals: {},
                    componentTotals: {},
                    dateTotals: {},
                    grandTotal: null,
                    dates: [],
                    datesTillNow: [],
                    components: [],
                    linkParams: null,
                }
            }

            const dates = getDates(res, year)

            return {
                years: res.years,
                maintenanceCost: getData(res.maintenanceCost, isAll),
                unitUtilizationDistance: getData(res.unitUtilizationDistance, isAll),
                unitUtilizationHours: getData(res.unitUtilizationHours, isAll),
                totals: getTotalsIndexedByComponent(res),
                componentTotals: getComponentTotalsIndexedByComponent(res),
                dateTotals: getDateTotalsIndexedByDate(res),
                grandTotal: res.grandTotal,
                dates,
                datesTillNow:
                    year && isCurrenYear(year) ? getMonths(year, getCurrentMonth() + 1) : dates,
                // Convert system into assembly
                components: res.components.flatMap((component) =>
                    component.level === CK33Levels.SYSTEM
                        ? [
                              { ...component, id: `system-${component.id}` },
                              { ...component, level: CK33Levels.ASSEMBLY },
                          ]
                        : [component],
                ),
                linkParams: {
                    ...getRange(year ? [+year] : [res.years[res.years.length - 1], res.years[0]]),
                    ...(record.customer ? { customer: record.customerData.name } : {}),
                    number: record.number,
                },
            }
        },
        {
            keepPreviousData: true,
            enabled: Boolean(record),
        },
    )

    return {
        appliedFilter: filter,
        resource: endpoint,
        selectedDate: year,
        data: result.data,
        isLoading: result.isLoading || !record,
        dateHelpers: isAll ? yearHelpers : monthHelpers,
        unitId,
    }
}

const endpoint = createReportResource('unit-cost')

export default useGetData
