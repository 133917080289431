import { type FC, type ReactNode } from 'react'

import { Typography } from 'ui'

interface Props {
    children: ReactNode
}

const InfoCardTitle: FC<Props> = ({ children }) => {
    return (
        <Typography
            color="inherit"
            variant="subtitle2"
        >
            {children}
        </Typography>
    )
}

export default InfoCardTitle
